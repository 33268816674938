import queryString from 'query-string';

import Cookies from 'js-cookie';

function setCookie(apiKey, hostKey) {
  Cookies.set('ascrd', `${apiKey}|||${hostKey}`);
}

function getCookie() {
  const cookieValue = Cookies.get('ascrd') || '';
  const [apiKey, hostKey] = cookieValue.split("|||");
  if (apiKey && hostKey) return [apiKey, hostKey];
}

export default function getCredentials(location) {
  const searchParams = location.search ? queryString.parse(location.search) : {};

  if (searchParams['env'] === 'local') {
    setCookie('search-1635t4uvafkwj6w2bw6gpc7t', 'host-2376rb');
    return ['search-1635t4uvafkwj6w2bw6gpc7t', 'host-2376rb'];
  }

  if (searchParams['api_key'] && searchParams['account_host_key']) {
    setCookie(searchParams['api_key'], searchParams['account_host_key']);
    return [searchParams['api_key'], searchParams['account_host_key']];
  }

  return getCookie() || [];
}
